var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "glances-cpu-gauge-wrapper" },
    [
      _c(
        "GaugeChart",
        {
          staticClass: "gl-speedometer",
          attrs: {
            value: _vm.gaugeValue,
            baseColor: _vm.baseColor,
            shadowColor: _vm.shadowColor,
            gaugeColor: _vm.gaugeColor,
            startAngle: _vm.startAngle,
            endAngle: _vm.endAngle,
            innerRadius: _vm.innerRadius,
            separatorThickness: _vm.separatorThickness,
          },
        },
        [
          _c("p", { staticClass: "percentage" }, [
            _vm._v(_vm._s(_vm.gaugeValue) + "%"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "show-more-btn", on: { click: _vm.toggleMoreInfo } },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.showMoreInfo
                  ? _vm.$t("widgets.general.show-less")
                  : _vm.$t("widgets.general.mem-details")
              ) +
              " "
          ),
        ]
      ),
      _vm.moreInfo && _vm.showMoreInfo
        ? _c(
            "div",
            { staticClass: "more-info" },
            _vm._l(_vm.moreInfo, function (info, key) {
              return _c("div", { key: key, staticClass: "more-info-row" }, [
                _c("p", { staticClass: "label" }, [_vm._v(_vm._s(info.label))]),
                _c("p", { staticClass: "value" }, [_vm._v(_vm._s(info.value))]),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }